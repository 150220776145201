<template>
  <van-tabbar route active-color="#10aec2">
    <van-tabbar-item icon="wap-home" to="/fog/">首页</van-tabbar-item>
    <van-tabbar-item icon="column" to="/fog/list">设备</van-tabbar-item>
    <van-tabbar-item icon="volume" to="/fog/history">历史</van-tabbar-item>
    <van-tabbar-item icon="setting" to="/fog/about">关于</van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: 'tabber',
}
</script>
